/** @jsx jsx */
import { jsx, Box } from 'theme-ui';
import { useState, useEffect, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as queryString from 'query-string';
import { getServicePoints } from '../state/servicePoints';
import Layout from '../components/layout';
import MapAndFilters from '../map/MapAndFilters';
import { getTranslate } from 'react-localize-redux';
import * as analytics from '../utils/analytics';

export default function ServicePoints({ location, pageContext }) {
  analytics.usePageCategory('palvelupisteet');
  const queryParams = queryString.parse(location.search);
  const { id: officeId, service: serviceParam } = queryParams;
  const serviceCodes = useMemo(() => (serviceParam || '').split(',').filter(s => s !== ''), [serviceParam]);
  const dispatch = useDispatch();
  const localize = useSelector(state => state.localize);
  const translate = getTranslate(localize);

  useEffect(() => {
    if (officeId) {
      const codes = (serviceParam || '').split(',').filter(s => s !== '');
      dispatch(getServicePoints(null, null, codes, officeId));
    }
  }, []);

  return (
    <Layout
      title={translate('servicePoints.title')}
      paths={pageContext.paths}
      locale={pageContext.locale || 'en'}
      description="Matkahuollon palvelupisteverkosto palvelee laajasti joka puolella Suomea, käytössäsi on lähes 2300 palvelupistettä sekä 1000 automaattia. Hae sinua lähin palvelupiste palvelupistehausta."
      showNavigation={false}
    >
      <MapAndFilters {...{ officeId, serviceCodes }} height={60} />
    </Layout>
  );
}
